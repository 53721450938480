import { useEffect, useState } from "react"
import { auth } from "./firebase"
import { signInWithEmailAndPassword,signOut} from 'firebase/auth'
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
export default function Login(){
    const [email,setemail] = useState("")
    const [password,setpassword] = useState("")
    const navigate = useNavigate();

    useEffect(()=>{
        signOut(auth);
    },[])
    function loginRequest(){
        signInWithEmailAndPassword(auth,email,password).then(()=>{
            swal("Giriş Başarılı!", "", "success");
            navigate('/');
        }).catch((err)=>{
            swal("Başarısız!",err.code ?? "", "error");
        })
    }
    return <div className="rp-app rp-getting-started">
    <div className="container-fluid">
      <div className="page-auth">
        <div className="rp-auth-head">
          <h2 className="title">Let's Sign You In</h2>
          <span className="text">Welcome Back, You've<br />been missed!</span>
        </div>
        <form>
          <div className="group">
            <label htmlFor="input1" className="label-inline">Your Email</label>
            <input type="email" className="form-control" id="input1" value={email} onChange={e=>setemail(e.target.value)} />
          </div>
          <div className="group">
            <label htmlFor="input2" className="label-inline">Password</label>
            <input type="password" className="form-control" id="input2" value={password} onChange={e=>setpassword(e.target.value)} />
          </div>
          <button className="btn btn-resto btn-block mt-5" onClick={(e)=>{e.preventDefault();loginRequest()}}>Login</button>
        </form>
        
      </div>
    </div>
  </div>
  
}