import { initializeApp } from 'firebase/app';
import { getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import { getFunctions,httpsCallable,connectFunctionsEmulator } from 'firebase/functions';
const firebaseConfig = {
    apiKey: "AIzaSyBkSeT7rhidE4f_KaUtVelF9MuLlloR9ME",
    authDomain: "restomenum3.firebaseapp.com",
    databaseURL: "https://restomenum3.firebaseio.com",
    projectId: "restomenum3",
    storageBucket: "restomenum3.appspot.com",
    messagingSenderId: "729316482166",
    appId: "1:729316482166:web:76110284ef5ee2b2681357",
    measurementId: "G-0MK9QTHRJR"
  };
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app,'europe-west3')
const [hostname] = window.location.hostname.split(".");
if (hostname === "localhost" || hostname.startsWith("192.168")) 
connectFunctionsEmulator(functions,"localhost",5001)
function callFunction (functionname){
  return httpsCallable(functions,functionname)
}
const auth = getAuth(app)
const firestore = getFirestore(app)
export { auth,app,firestore,callFunction };