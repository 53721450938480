import {Helmet} from "react-helmet";
import { useRef, useState,useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import React from 'react';
import ContentEditable from 'react-contenteditable'
import { Stack } from 'react-bootstrap';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { callFunction, firestore } from './firebase';
import {  doc, getDoc } from "firebase/firestore";
import { auth } from "./firebase"
import {  toast } from 'react-toastify';
function convertBase64 (file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result);
    }
    fileReader.onerror = (error) => {
      reject(error);
    }
  })
}
function removeHtml(value){
  return value.replace(/(<([^>]+)>)/ig,"")
}
function makeChunks(array,chunkSize){
  let arr = []
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    arr.push(chunk)
      // do whatever
  }
  return arr;
}
function Home() {
  const site = window.location.host;
  // const site = "lalunepatisserie.com";
  useEffect(()=>{
    try {
       getDoc(doc(firestore,"/website/"+site)).then(snapshot=>{
        if(snapshot.exists()){
          const data = snapshot.data();
          console.log(data)
          data.sliders && setsliders(data.sliders);
          data.logo && setlogo(data.logo);
          data.sollogo && setsollogo(data.sollogo);
          data.serverId && setserverId(data.serverId);
          data.ikibaslik && setikibaslik(data.ikibaslik);
          data.ortaimage && setortaimage(data.ortaimage);
          data.ikiaciklama && setikiaciklama(data.ikiaciklama);
          data.ikiimage && setikiimage(data.ikiimage);
          data.ucbaslik && setucbaslik(data.ucbaslik);
          data.uc1baslik && setuc1baslik(data.uc1baslik);
          data.uc1aciklama && setuc1aciklama(data.uc1aciklama);
          data.uc2baslik && setuc2baslik(data.uc2baslik);
          data.uc2aciklama && setuc2aciklama(data.uc2aciklama);
          data.dortbaslik && setdortbaslik(data.dortbaslik);
          data.videobaslik && setvideobaslik(data.videobaslik);
          data.videoimage && setvideoimage(data.videoimage);
          data.videolink && setvideolink(data.videolink);
          data.telefon && settelefon(data.telefon);
          data.mail && setmail(data.mail);
          data.adress && setadress(data.adress);
          data.gallery && setgallery(data.gallery);
          data.gallerytitle && setgallerytitle(data.gallerytitle);
          data.products && setproducts(data.products);
          data.bgcolor1 && setbgcolor1(data.bgcolor1);
          data.bgcolor2 && setbgcolor2(data.bgcolor2);
          data.buttonColor && setbuttonColor(data.buttonColor);
          setTimeout(() => setloading(false), 1000); 
          (Array.isArray(data.dortelements) && data.dortelements.length==6) && setdortelements(data.dortelements)
        }else{
          console.log("Page Not Found.")
          toast.error("Page Not Found.")
        }
       })
    } catch (error) {
      console.log(error)
    }
    const sub = auth.onAuthStateChanged(user=>{
      setuser(user)
    })
    return sub;
  },[window.location.host])
   const [sliders,setsliders] = useState([]);
  function editSlider(index,field,value){
    var copy = JSON.parse(JSON.stringify(sliders));
    if(!copy[index]){
      copy[index] = {title:"Lorem ipsum",desc:"Lorem ipsum"}
    }
    copy[index][field] = value;
    setsliders(copy);
  }
  const [dortelements,setdortelements] = useState([...Array(6).keys()].map(v=>({title:"Lorem ipsum",desc:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veritatis beatae officia doloremque fugit."})));
  function editElements(index,field,value){
    var copy = JSON.parse(JSON.stringify(dortelements));
    copy[index][field] = value;
    setdortelements(copy);
  }
  const timeoutRef = useRef({});
  const [loading,setloading] = useState(true);
  const [user,setuser] = useState(false);
  const [serverId,setserverId] = useState("");
  const isAdmin = (user && user.uid==serverId) 
  const [products,setproducts] = useState([]);
  const [facebook,setfacebook] = useState("");
  const [twitter,settwitter] = useState("");
  const [buttonColor,setbuttonColor] = useState("red");
  const [bgcolor1,setbgcolor1] = useState("#f1cd21");
  const [bgcolor2,setbgcolor2] = useState("#f27507");
  const [instagram,setinstagram] = useState("");
  const [logo,setlogo] = useState("assets/img/logo.png");
  const [ortaimage,setortaimage] = useState("assets/img/pizza-png.png");
  const [sollogo,setsollogo] = useState("assets/img/coffee-left.png");
  const [ikiimage,setikiimage] = useState("assets/img/image-feature.png");
  const [videoimage,setvideoimage] = useState("assets/img/parallax.jpg");
  const [ikibaslik,setikibaslik] = useState("We made the best burger in the town");
  const [ikiaciklama,setikiaciklama] = useState("We made the best burger in the town");
  const [ucbaslik,setucbaslik] = useState("Lorem ipsum");
  const [uc1baslik,setuc1baslik] = useState("Lorem ipsum");
  const [uc1aciklama,setuc1aciklama] = useState("Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veritatis beatae officia doloremque fugit.");
  const [uc2baslik,setuc2baslik] = useState("Lorem ipsum");
  const [uc2aciklama,setuc2aciklama] = useState("Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veritatis beatae officia doloremque fugit.");
  const [dortbaslik,setdortbaslik] = useState("Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veritatis beatae officia doloremque fugit.");
  const [videobaslik,setvideobaslik] = useState("Lorem ipsum");
  const [videolink,setvideolink] = useState("https://www.youtube.com/embed/5xrtwEGRQJk");
  const [telefon,settelefon] = useState("+90 850 000 00 00");
  const [mail,setmail] = useState("info@restomenum.com");
  const [adress,setadress] = useState("FENERBAHÇE MAH. İĞRİP SK. 13/1 KADIKÖY/İSTANBUL");
  const [gallerytitle,setgallerytitle] = useState("Galeri");
  const [gallery,setgallery] = useState(["assets/img/parallax.jpg"]);
  function updateField(){
    if(!isAdmin){
      console.log("isNot admin")
      return false;
    }
    if(timeoutRef.current){
      console.log("timeout already exists...")
      timeoutRef.current = clearTimeout(timeoutRef.current)
      return ;
    }
    console.log("new timeout set")
    timeoutRef.current = setTimeout(()=>{
     callFunction("v3")({
        data:{
          logo,ikibaslik,ikiaciklama,ucbaslik,uc1baslik,uc1aciklama,uc2baslik,uc2aciklama,dortbaslik,videobaslik,videolink,ortaimage,videoimage,
          telefon,mail,adress,gallery,dortelements,gallerytitle,ikiimage,sliders:sliders.filter(v=>!(v.title+"").startsWith("Lorem ipsum")),sollogo
        },
        serverId,
        endpoint:"EntegrasyonSetConfig",
        docId:"website",
        domain:site,
        entegrasyon:"website",
        uid:auth.currentUser?.uid
      })
      .then(()=> toast.success("Site Güncellendi", {
        render:"Kaydedildi!",
        type: "success", isLoading: false,
        position: "top-right",
        autoClose: 5000,
        theme: "light",
        }))
        .catch(console.log)
    },5000)
  }
  useEffect(()=>{
    isAdmin && updateField()
  },[logo,ikibaslik,ikiaciklama,ikiimage,ucbaslik,uc1baslik,uc1aciklama,uc2baslik,uc2aciklama,dortbaslik,videobaslik,videolink,telefon,mail,adress,gallery,dortelements,sliders,gallerytitle,ortaimage,videoimage,sollogo])
  function myPropt(e,title,cb){
    if(!isAdmin) return null;
    e.preventDefault();
    const value = prompt(title)
    cb(value);
  }
  const productsChunk = makeChunks(products,3)

  const hoverStyle = {
    width:window.innerWidth/4,
    height:400
  }
  if(loading){
    return <div className="wrapperbody"><div class="wrapper">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        {/* <span>Yükleniyor..</span> */}
    </div></div>
  }
  return (
 <>
    <div className="rp-app">
  <div className="rp-landing-page">
    <div className="rp-fixed-left" style={{pointerEvents:isAdmin?"auto":"none"}}>
      <img src={sollogo} alt="coffee" />
      {isAdmin && <input type="file" accept="image/*"  placeholder="Dosya Seç" onChange={(e)=>{
      const [file] = e.target.files
      convertBase64(file).then(setsollogo)
  }}/>}
    </div>
    <div className="rp-social-buttons for-landing">
     {(facebook || isAdmin) && <a href={facebook} className="link fb" onClick={(e)=> myPropt(e,"facebook",setfacebook.bind(this))}><i className="fab fa-facebook-f" /></a>}
     {(twitter || isAdmin) && <a href={twitter} className="link tw" onClick={(e)=> myPropt(e,"twitter",settwitter.bind(this))}><i className="fab fa-twitter" /></a>}
     {(instagram || isAdmin) && <a href={instagram} className="link ig" onClick={(e)=> myPropt(e,"instagram",setinstagram.bind(this))}><i className="fab fa-instagram" /></a>}
      {/* <a href="#" className="link like"><i className="fa fa-heart" /> <span>Bizi<br />Değerlendirin!</span></a> */}
    </div>
    <div className="rp-lp-logo">
      <img src={logo} alt="logo" />
      {isAdmin && <input type="file" accept="image/*" style={{position: "absolute",left:0,top:0}} placeholder="Dosya Seç" onChange={(e)=>{
      const [file] = e.target.files
      convertBase64(file).then(setlogo)
  }}/>}
                 
    </div>
    <div className="rp-lp-banner" style={{backgroundImage: `linear-gradient(to bottom right, ${bgcolor1}, ${bgcolor2 })`}}>
    <Carousel  >
      {[...Array(sliders.length+(isAdmin?1:0)).keys()].map((v,k)=>{
      const value = sliders[v];
      return <Carousel.Item className="item" key={k}>
        <div className="item">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-7">
              <h1 className="title" ><ContentEditable disabled={!isAdmin}  html={value?.title ?? "Lorem ipsum"} onChange={e=>editSlider(v,"title",removeHtml(e.target.value))}/></h1>
                <h2 className="subtitle"><ContentEditable disabled={!isAdmin} html={value?.desc ?? "Lorem ipsum"} onChange={e=>editSlider(v,"desc",removeHtml(e.target.value))}/></h2>
              
                {/* <div className="buttons d-mobile">
                  <a href="#" className="btn btn-dark btn-block">Menü</a>
                  <a href="#" className="btn btn-dark btn-block">Garson Çağır</a>
                  <a href="#" className="btn btn-dark btn-block">Sipariş Ver</a>
                </div> */}
              </div>
              <div className="col-5">
                <div className="rp-animated-area">
                  <img src={value?.image ?? "assets/img/shape1.png"} alt="img" className="img1" />
                  {isAdmin && <input type="file" accept="image/*"  style={{marginLeft: "auto",bottom: 0,position: "absolute",zIndex: 9999}} placeholder="Dosya Seç" onChange={(e)=>{
                      const [file] = e.target.files
                      convertBase64(file).then(value=>editSlider(v,"image",value))
                  }}/>}
                  {/* <img src="assets/img/shape2.png" alt="img" className="img2" />
                  <img src="assets/img/shape3.png" alt="img" className="img3" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>})}
     

    </Carousel>
   
      {/* <div className="container">
        <div className="buttons d-desktop">
          <a href="#" className="btn btn-dark btn-block">Menü</a>
          <a href="#" className="btn btn-dark btn-block">Garson Çağır</a>
          <a href="#" className="btn btn-dark btn-block">Sipariş Ver</a>
        </div>
      </div> */}
    </div>
    <div className="rp-lp-feature">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="content">
              <h2 className="title" ><ContentEditable disabled={!isAdmin} html={ikibaslik} onChange={e=>{setikibaslik(removeHtml(e.target.value));}}/></h2>
              <span><ContentEditable disabled={!isAdmin} html={`${ikiaciklama}`} onChange={e=>{setikiaciklama(removeHtml(e.target.value));}}/></span>
              {/* <a href="#"   style={{marginTop:10}} className="btn btn-green">Daha Fazla Bilgi</a> */}
            </div>
          </div>
          <div className="col-md-6">
            <img src={ikiimage} alt="image" className="img" />
            {isAdmin && <input type="file" accept="image/*" placeholder="Dosya Seç" onChange={(e)=>{
                      const [file] = e.target.files
                      convertBase64(file).then(setikiimage)
                  }}/>}
          </div>
        </div>
      </div>
    </div>
    <div className="rp-lp-section">
      <div className="container">
        <h2 className="section-title"><ContentEditable disabled={!isAdmin} html={ucbaslik} onChange={e=>setucbaslik(removeHtml(e.target.value))}/></h2>
       
        <Carousel  >
        {productsChunk.map((products,index)=><Carousel.Item  key={index}>
        <Stack
                direction="horizontal"
                className="h-200 justify-content-center align-items-center"
                gap={products.length}
              >
             {products.map((v,k)=><div className="rp-lp-product" style={hoverStyle} key={k}>
              <h4 className="name">{v.qrTitle ?? v.title}</h4>
              <p className="desc text-muted">{v.qrDesc ?? ""}</p>
              <span className="price">{v.price}</span>
              <a href="#" className="button"><i className="fa fa-shopping-cart" /> Sipariş Ver</a>
              <div className="img mt-3"><img src={v.image} alt={v.title} /></div>
            </div>)}
      
                </Stack>
            </Carousel.Item>)}
         
          </Carousel  >
      </div>
    </div>
    <div className="rp-lp-motor">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="content">
              {/* <h5 className="subtitle">Delivery</h5> */}
              <h3 className="title"><ContentEditable disabled={!isAdmin} html={uc1baslik} onChange={e=>setuc1baslik(removeHtml(e.target.value))}/></h3>
              <span><ContentEditable disabled={!isAdmin} html={uc1aciklama} onChange={e=>setuc1aciklama(removeHtml(e.target.value))}/></span>
              <a href="#" className="button btn btn-block mt-3" style={{marginTop:10,backgroundColor:buttonColor}}><i className="fa fa-shopping-cart" /> Menu</a>
            </div>
            <div className="delivery-area">
              <img src="assets/img/delivery-motor.png" alt="delivery" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="content">
              {/* <h5 className="subtitle">Delivery</h5> */}
              <h3 className="title"><ContentEditable disabled={!isAdmin} html={uc2baslik} onChange={e=>setuc2baslik(removeHtml(e.target.value))}/></h3>
              <span><ContentEditable disabled={!isAdmin} html={uc2aciklama} onChange={e=>setuc2aciklama(removeHtml(e.target.value))}/></span>
               <a href="#" className="button btn btn-block mt-3" style={{marginTop:10,backgroundColor:buttonColor}}><i className="fa fa-shopping-cart" /> Online Sipariş Ver</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="rp-lp-six">
      <div className="container">
        <h2 className="section-title"><ContentEditable disabled={!isAdmin} html={dortbaslik} onChange={e=>setdortbaslik(removeHtml(e.target.value))}/></h2>
        <div className="row align-items-center">
          <div className="col-lg-3">
            <div className="feature">
              <h4 className="title"><ContentEditable disabled={!isAdmin} html={dortelements[0]?.title ??"Lorem, ipsum"} onChange={e=>editElements(0,"title",removeHtml(e.target.value))}/></h4>
              <p><ContentEditable disabled={!isAdmin} html={dortelements[0]?.desc ??"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus similique amet accusamus! Impedit."} onChange={e=>editElements(0,"desc",removeHtml(e.target.value))}/></p>
            </div>
            <div className="feature">
              <h4 className="title"><ContentEditable disabled={!isAdmin} html={dortelements[1]?.title ??"Lorem, ipsum"} onChange={e=>editElements(1,"title",removeHtml(e.target.value))}/></h4>
              <p><ContentEditable disabled={!isAdmin} html={dortelements[1]?.desc ??"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus similique amet accusamus! Impedit."} onChange={e=>editElements(1,"desc",removeHtml(e.target.value))}/></p>
           </div>
            <div className="feature">
              <h4 className="title"><ContentEditable disabled={!isAdmin} html={dortelements[2]?.title ??"Lorem, ipsum"} onChange={e=>editElements(2,"title",removeHtml(e.target.value))}/></h4>
              <p><ContentEditable disabled={!isAdmin} html={dortelements[2]?.desc ??"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus similique amet accusamus! Impedit."} onChange={e=>editElements(2,"desc",removeHtml(e.target.value))}/></p>
            </div>
          </div>
          <div className="col-lg-6">
            <img src={ortaimage} alt="pizza" />
            {isAdmin &&<center> <input type="file" accept="image/*" placeholder="Dosya Seç" onChange={(e)=>{
                      const [file] = e.target.files
                      convertBase64(file).then(setortaimage)
                  }}/></center>}
                 
          </div>
          <div className="col-lg-3">
            <div className="feature">
              <h4 className="title"><ContentEditable disabled={!isAdmin} html={dortelements[3]?.title ??"Lorem, ipsum"} onChange={e=>editElements(3,"title",removeHtml(e.target.value))}/></h4>
              <p><ContentEditable disabled={!isAdmin} html={dortelements[3]?.desc ??"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus similique amet accusamus! Impedit."} onChange={e=>editElements(3,"desc",removeHtml(e.target.value))}/></p>
            </div>
            <div className="feature">
              <h4 className="title"><ContentEditable disabled={!isAdmin} html={dortelements[4]?.title ??"Lorem, ipsum"} onChange={e=>editElements(4,"title",removeHtml(e.target.value))}/></h4>
              <p><ContentEditable disabled={!isAdmin} html={dortelements[4]?.desc ??"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus similique amet accusamus! Impedit."} onChange={e=>editElements(4,"desc",removeHtml(e.target.value))}/></p>
            </div>
            <div className="feature">
              <h4 className="title"><ContentEditable disabled={!isAdmin} html={dortelements[5]?.title ??"Lorem, ipsum"} onChange={e=>editElements(5,"title",e.target.value)}/></h4>
              <p><ContentEditable disabled={!isAdmin} html={dortelements[5]?.desc ??"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus similique amet accusamus! Impedit."} onChange={e=>editElements(5,"desc",removeHtml(e.target.value))}/></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="rp-lp-video" style={{backgroundImage: 'url("'+videoimage+'")'}}>
      <div className="center">
        <h2 className="title"><ContentEditable disabled={!isAdmin} html={videobaslik} onChange={e=>setvideobaslik(removeHtml(e.target.value))}/></h2>
        {isAdmin && <input type="file" accept="image/*" placeholder="Dosya Seç" onChange={(e)=>{
                      const [file] = e.target.files
                      convertBase64(file).then(setvideoimage)
                  }}/>}
        {isAdmin &&<input className='form-control' style={{marginTop:10,marginBottom:10}} value={videolink} onChange={e=>setvideolink(e.target.value)}/>}
        <button type="button" className="button" style={{backgroundColor:buttonColor}}><i className="fa fa-play" /></button>
      </div>
    </div>
    <div className="modal fade modalvideo" id="videoModal" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="video-100">
              <iframe width="100%" height="100%" src={videolink} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <div className="rp-lp-form">
      <div className="container">
        <form>
          <h2 className="title">Rezervasyon</h2>
          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <label>İsim ve Soyisim</label>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <label>İsim ve Soyisim</label>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <label>İsim ve Soyisim</label>
                <input type="text" className="form-control" />
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-red btn-block mt-1">Rezervasyon Kayıt</button>
        </form>
      </div>
    </div> */}
     <div className="rp-lp-gallery rp-lp-section pt-4 pb-0">
     <div className="container p-0">
      <h2 className="section-title"><ContentEditable disabled={!isAdmin} html={gallerytitle} onChange={e=>setgallerytitle(removeHtml(e.target.value))}/></h2>
        {isAdmin && <div className="row">
          {[...Array(gallery.length+1).keys()].map((v)=><div className="col-4 mb-3" key={v}>
            <img src={gallery[v] ?? "assets/img/parallax.jpg"} style={{maxHeight:200}} />
            <div style={{display:'flex',justifyContent:"space-between",margin:10}}>
            <input type="file" accept="image/*" placeholder="Dosya Seç" style={{position:"relative",left:0,top:0}} onChange={(e)=>{
              const [file] = e.target.files
              convertBase64(file).then(value=>setgallery(state=>state.length<(v+1) ? [...state,value]: [...state.map((va,ka)=>ka!=v?va:value)]))
            }}/>
            <button className="btn btn-xs btn-danger" disabled={v==gallery.length} onClick={()=>setgallery(state=>state.filter((va,ka)=>ka!=v))}>Sil</button>
            </div>
          </div>)}
          </div>}
        {!isAdmin && <LightGallery elementClassNames='gallery-grid' 
               disabled={true}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
            >
               {
                [...Array(gallery.length).keys()].map((v,k)=><a href={gallery[v] ?? "assets/img/parallax.jpg"} className='img' key={k}>
                <img alt="img1" src={gallery[v] ?? "assets/img/parallax.jpg"} />
                </a >)
               } 
               
              
               
            </LightGallery>}
            </div>
      </div>
    {/* <div className="rp-lp-gallery rp-lp-section pt-4 pb-0">
      <div className="container p-0">
        <h2 className="section-title">GALERİ</h2>
        <div className="gallery-grid" id="zoom-gallery">
          <a href="assets/img/parallax.jpg" className="img">
            <img src="assets/img/parallax.jpg" />
          </a>
          <a href="assets/img/parallax.jpg" className="img">
            <img src="assets/img/parallax.jpg" />
          </a>
          <a href="assets/img/parallax.jpg" className="img">
            <img src="assets/img/parallax.jpg" />
          </a>
          <a href="assets/img/parallax.jpg" className="img">
            <img src="assets/img/parallax.jpg" />
          </a>
          <a href="assets/img/parallax.jpg" className="img">
            <img src="assets/img/parallax.jpg" />
          </a>
          <a href="assets/img/parallax.jpg" className="img">
            <img src="assets/img/parallax.jpg" />
          </a>
        </div>
      </div>
    </div> */}
    <div style={{height: '150px', overflow: 'hidden'}}><svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}><path d="M-0.27,151.48 C368.22,84.38 139.67,84.38 523.42,156.42 L502.54,152.47 L-16.64,152.47 Z" style={{stroke: 'none', fill: '#3e3b50'}} /></svg></div>
    <div className="rp-lp-footer">
      <div className="container">
        <img src="assets/img/logo.png" alt="logo" className="logo" />
        <span  className="phone"><ContentEditable disabled={!isAdmin} html={telefon} onChange={e=>settelefon(removeHtml(e.target.value))}/></span>
        <span  className="mail"><ContentEditable disabled={!isAdmin} html={mail} onChange={e=>setmail(removeHtml(e.target.value))}/></span>
        <span className="address"><ContentEditable disabled={!isAdmin} html={adress} onChange={e=>setadress(removeHtml(e.target.value))}/></span>
        <ul className="social list-unstyled mb-0">
       
          <li>  
            {(facebook || isAdmin) && <a href={facebook} className="link fb" onClick={(e)=> myPropt(e,"facebook",setfacebook.bind(this))}><i className="fab fa-facebook-f" /></a>}
           </li>
          <li> 
            {(twitter || isAdmin) && <a href={twitter} className="link tw" onClick={(e)=> myPropt(e,"twitter",settwitter.bind(this))}><i className="fab fa-twitter" /></a>}
           </li>
          <li>
            {(instagram || isAdmin) && <a href={instagram} className="link ig" onClick={(e)=> myPropt(e,"instagram",setinstagram.bind(this))}><i className="fab fa-instagram" /></a>}
           </li>
         
        </ul>
      </div>
    </div>
  </div>
</div>

</>
  );
}

export default Home;
