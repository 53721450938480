import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function App() {
  return (
    <BrowserRouter>
     <ToastContainer limit={1} />
      <Routes>
          <Route path="/" index element={<Home />}/>
            <Route path="/login"  element={<Login />}/>
          <Route path="*" index element={<Home />}/>
     
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);